<template>
  <div>
    <Breadcrumbs title="Settlements"/>
    <div class="container-fluid" v-if="loaded">

      <form @submit.prevent="fetchData" class="card">
        <div class="card-body">

          <b-row>
            <b-col cols="12" md="3">
              <div class="form-group">
                <label class="control-label">Institute <span class="font-danger">*</span></label>
                <b-form-select :disabled="!institutes.length" @change="fetchClasses" :state="hasError('institute_id')" :options="institutes" value-field="id" text-field="name" class="btn-square border" v-model="formSearch.institute_id"></b-form-select>
                <b-form-invalid-feedback :state="hasError('institute_id')">{{getError('institute_id')}}</b-form-invalid-feedback>
              </div>
            </b-col>
            <b-col cols="12" md="3">
              <div class="form-group">
                <label class="control-label">Lecturer <span class="font-danger">*</span></label>
                <b-form-select class="btn-square border" @change="fetchClasses" v-model="formSearch.teacher_id" :options="lecturers" value-field="id" text-field="text"></b-form-select>
              </div>
            </b-col>
            <b-col cols="12" md="5">
              <div class="form-group">
                <label class="control-label">Class <span class="font-danger">*</span></label>
                <b-form-select class="btn-square border" v-model="formSearch.class_detail_id" :options="class_details" value-field="id" text-field="text"></b-form-select>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="text-center">
              <b-button :disabled="!formSearch.teacher_id" class="btn-square m-r-10 m-t-30 text-uppercase" variant="primary" @click="fetchData">View</b-button>
              <b-button :disabled="!formSearch.teacher_id" class="btn-square m-r-10 m-t-30 text-uppercase" variant="danger" @click="settlePayments">Settle Now</b-button>
            </b-col>
          </b-row>

        </div>
      </form>


      <div class="card">
        <div class="card-body">

          <div class="table-responsive datatable-vue">
            <b-table
                ref="table"
                show-empty
                selected-variant="success"
                :items="paymentRecords"
                :fields="tableFields"
                :filter="filter"
                :current-page="currentPage"
                :per-page="perPage"
            >
              <template #cell(cashier_name)="row">
                <div>{{ row.item.creator.first_name }} {{ row.item.creator.last_name }}</div>
              </template>
              <template #cell(teacher_name)="row">
                <div>{{ row.item.user.first_name }} {{ row.item.user.last_name }}</div>
              </template>
              <template #cell(created_at)="row">
                {{ new Date(row.item.created_at).toDateString() }}
              </template>
              <template #cell(amount)="row">Rs. {{ row.item.amount }}</template>
              <template #cell(action)="row" class="text-right">
                <router-link :to="{ name: 'settlements-view', params: { settlementId: row.item.id } }" v-show="checkUserPermissions(user,['view_settlements'])" class="btn btn-square btn-primary mr-1">View</router-link>
              </template>
            </b-table>
          </div>

          <b-row >
            <b-col cols="4">
              Records: <strong>{{ totalRows }}</strong>
            </b-col>
            <b-col cols="8" class="my-1" v-if="totalRows">
              <b-pagination
                  align="right"
                  class="my-0"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  pills
              ></b-pagination>
            </b-col>
          </b-row>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {checkUserPermissions} from "@/plugins/functions";
import API from "@/services/api";
import CRUD_API from "@/services/crud";
export default {
  name: "settlements_index",
  watch: {
    currentPage() {
      this.fetchData()
    },
    perPage() {
      this.currentPage = 1
      this.fetchData()
    },
    filter() {
      this.currentPage = 1
      this.fetchData()
    },
    lecturerSearch: function (lecturer) { this.fetchLecturers(lecturer) },
  },
  computed: {
    ...mapState({ user: state => JSON.parse(state.auth.user) }),
    institutes() {
      let institute_list = this.user.institutes
      institute_list.unshift({
        id: null,
        name: "All Institutes"
      })
      return institute_list
    }
  },
  mounted() {
    this.fetchCashiers();
    this.fetchLecturers();
    this.fetchCashiers();
  },
  data() {
    return {
      api_base: '/api/backend/pages/',
      errorBag: null,
      filter: '',
      totalRows: 0,
      perPage: 0,
      currentPage: 1,
      formSearch: {
        teacher_id: null,
        creator_id: null,
        institute_id: null,
        class_detail_id: null,
        settlement_date: '',
        class_list: []
      },
      paymentRecords: [],
      settlementStatus: 1,
      tableFields: [
        { key: 'id', label: 'Settlement ID', sortable: false },
        { key: 'created_at', label: 'Date / Time', sortable: true },
        { key: 'institute.name', label: 'Institute', sortable: true },
        { key: 'teacher_name', label: 'Lecturer Name', sortable: true },
        { key: 'cashier_name', label: 'Cashier Name', sortable: true },
        { key: 'old_id', label: 'Old ID', sortable: false },
        { key: 'amount', label: 'Net Amount', sortable: false, class: 'text-right' },
        { key: 'action', label: 'Action', sortable: false, class: 'text-right text-capitalize' }
      ],

      loaded: false,
      cashiers: [],
      lecturers: [],
      class_details: [{id:null,text:"All Classes"}],
      lecturerSearch: '',
      selectedLecturer: null,
    }
  },
  methods: {
    checkUserPermissions,
    clearErrors(){ this.errorBag = []; },
    hasError(ifn){ if (this.errorBag) { return !this.errorBag.hasOwnProperty(ifn); } return null; },
    getError(ifn){ if(this.errorBag) { if (!this.hasError(ifn)) { return this.errorBag[ifn][0]; } } return null; },

    async fetchCashiers(){
      this.loaded = false
      API.select('cashiers')
          .then((result) => {
            this.cashiers = result.data.data
            this.loaded = true
          }).catch((error)=>{
        console.log(error)
        this.cashiers = []
        this.errorBag = error.response.data.data
      }).finally(() => {
        this.paymentRecords = []
        this.totalRows = 0
        this.cashiers.unshift({
          id: null,
          text: "All Cashiers"
        })
      })
    },
    async fetchLecturers(query) {
      API.select('teachers', { filter: query })
          .then((response) => {
            this.lecturers = response.data.data
            if (this.lecturers.length === 1) {
              this.formSearch.teacher_id = this.lecturers[0].id
              this.fetchClasses()
            }
          })
          .catch((error) => {
            console.log(error)
            this.lecturers = []
            this.$toasted.error(error.message)
          }).finally(() => {
        this.paymentRecords = []
        this.totalRows = 0
        this.lecturers.unshift({
          id: null,
          text: "All Lecturers"
        })
      })
    },
    async fetchClasses(){
      API.select('classes', {
        'teacher_id': this.formSearch.teacher_id,
        'institute_id': this.formSearch.institute_id,
        'settlement_date': this.formSearch.settlement_date
      }).then((result) => {
        this.class_details = result.data.data
        console.log("CLASSES", result.data)
      }).catch((error) => {
        console.log("CLASSES", error)
        this.class_details = []
        this.$toasted.error("Classes could not be retrieved")
      }).finally(() => {
        this.paymentRecords = []
        this.totalRows = 0
        this.class_details.unshift({
          id: null,
          text: "All Classes"
        })
      })
    },
    fetchData(){
      this.paymentRecords = []
      if(this.formSearch.teacher_id) {
        CRUD_API.index(this.api_base + 'settlements', {
          params: {
            per_page: this.perPage,
            page: this.currentPage,
            teacher_id: this.formSearch.teacher_id,
            creator_id: this.formSearch.creator_id,
            institute_id: this.formSearch.institute_id,
            class_detail_id: this.formSearch.class_detail_id,
            settlement_date: this.formSearch.settlement_date,
            settlement_status: this.settlementStatus
          }
        }).then((response) => {
          this.paymentRecords = response.data.data.data
          this.totalRows = response.data.data.total
          if (!this.totalRows) {
            this.$toasted.success("No Records found.")
          }
        }).catch((errors) => {
          console.log(errors)
          this.paymentRecords = []
          this.errorBag = errors.response.data.data
          this.$toasted.error("Error occurred during data retrieval.")
        })
      } else {
        this.$toasted.error("Lecturer must be selected.")
      }
    },
    async settlePayments(){
      this.formSearch.payments = this.paymentRecords.filter(record => record.settlement_id === null).map((rec) => { return rec.id })
      if(!this.formSearch.payments.length) {
        this.$toasted.error("No data selected!")
      } else {
        await CRUD_API.create(this.api_base + 'settlements', this.formSearch)
            .then((result)=>{
              console.log(result)
              if(result.data.success) {
                this.$toasted.success("Payments settled")
                // alert(JSON.stringify(result.data.data.id))
                this.$router.push({ name: 'teacher-settlement-report-view', params: { settlementId: result.data.data.id } })
              } else {
                this.errorBag = result.data.data
                this.$toasted.error(result.data.message)
              }
            }).catch((error) => {
              console.log(error)
              this.errorBag = error.response.data.data
              this.$toasted.error(error.message)
            }).finally(() => {
              this.fetchData()
            })
      }
    },
    printWindow() {},
  },
}
</script>

<style scoped>

</style>